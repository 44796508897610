"use client";

// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- It's fine here, this is the root implementation
import NextLink from "next/link";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- It's fine here, this is the root implementation
import { usePathname } from "next/navigation";
import { forwardRef, useContext } from "react";

import { LocalizationContext } from "../contexts/LocalizationProvider";
import { URLLocalizer } from "./URLLocalizer";
import { useClientLocale } from "./useClientLocale";

export type LinkProps = {
    newTab?: boolean;
    locale?: string;
} & React.ComponentProps<typeof NextLink>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link({ locale: propsLocale, ...props }, ref) {
    const defaultLocale = useClientLocale();
    const locale = propsLocale ?? defaultLocale;
    const localizer = new URLLocalizer(useContext(LocalizationContext).viewer);
    if (!locale || !localizer.shouldLocalize()) {
        return <BaseLink ref={ref} {...props} />;
    }
    return <NavLink ref={ref} locale={locale} localizer={localizer} {...props} />;
});

export type NavLinkProps = {
    newTab?: boolean;
    locale: string;
    localizer: URLLocalizer;
} & React.ComponentProps<typeof NextLink>;
const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(function NavLink(
    { locale, localizer, href, prefetch, onClick, ...props },
    ref
) {
    const pathname = usePathname();
    const defaultLocale = useClientLocale()!;

    const localizedHref = localizer.localizeHref(href, locale, defaultLocale, pathname);
    const isChangingLocale = locale !== defaultLocale;
    if (isChangingLocale) {
        if (prefetch && process.env.NODE_ENV !== "production") {
            console.error(
                "The `prefetch` prop is currently not supported when using the `locale` prop on `Link` to switch the locale.`"
            );
        }
        prefetch = false;
    }

    return <BaseLink ref={ref} href={localizedHref} prefetch={prefetch} onClick={onClick} {...props} />;
});

type BaseLinkProps = {
    newTab?: boolean;
} & React.ComponentProps<typeof NextLink>;
const BaseLink = forwardRef<HTMLAnchorElement, BaseLinkProps>(function BaseLink({ newTab, onClick, ...props }, ref) {
    return (
        <NextLink
            ref={ref}
            onClick={onClick}
            rel={newTab ? "nofollow" : undefined}
            {...props}
            target={newTab ? "_blank" : undefined}
        />
    );
});
