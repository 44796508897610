export * from "./buildOrigin";

export type CurationNewParams = {
    curationId: string;
    slug?: string;
    curationIdsPath?: string[];
};

export const CURATION_PATH_QUERY_STRING_PARAM = "path";
export const UrlQueryParams = {
    curationPath: "path",
} as const;

export const getCurationPathQueryParam = (curationIdsPath?: string[]) => {
    return {
        name: CURATION_PATH_QUERY_STRING_PARAM,
        value: curationIdsPath?.length ? curationIdsPath.join(",") : undefined,
    };
};

export function urlCuration(params: CurationNewParams) {
    const { curationId, slug, curationIdsPath } = params;
    const curationPathParam = getCurationPathQueryParam(curationIdsPath);

    let defaultPath = `/curation/${curationId}`;
    defaultPath = slug ? `${defaultPath}/${slug}` : defaultPath;

    return curationPathParam.value
        ? `${defaultPath}?${curationPathParam.name}=${curationPathParam.value}`
        : defaultPath;
}

export function urlProduct(slugOrId: string, curationIdsPath?: string[]) {
    const defaultPath = `/product/${slugOrId}`;
    const curationPathParam = getCurationPathQueryParam(curationIdsPath);

    return curationPathParam.value
        ? `${defaultPath}?${curationPathParam.name}=${curationPathParam.value}`
        : defaultPath;
}

export function urlCustomPage(path: string) {
    return `/page/${removeLeadingSlash(path)}`;
}

export function urlBlogIndex() {
    return "/blog";
}

export function urlBlogPost(path: string) {
    return `/blog/${removeLeadingSlash(path)}`;
}

export function urlBlogPostsForAuthor(authorSlug: string) {
    return `/blog/author/${removeLeadingSlash(authorSlug)}`;
}

export function urlBlogCategory(slug: string) {
    return `/blog/category/${removeLeadingSlash(slug)}`;
}

export function removeLeadingSlash(path: string) {
    return path.replace(/^\//, "");
}

export function normalizePathPart(path: string) {
    return `/` + removeLeadingSlash(path);
}

export function urlPlace(id: string) {
    return `/place/${id}`;
}

export function urlProductSearch(searchValue: string) {
    const queryParams = new URLSearchParams();
    queryParams.append("filter", JSON.stringify({ search: searchValue }));
    return `/product?${queryParams.toString()}`;
}
