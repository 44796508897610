"use client";

import Box from "@mui/material/Box";
import Container, { ContainerProps } from "@mui/material/Container";
import { TextBaseProps, TextTitle, TextTitle2, TextTitle3 } from "components/Text";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    page: {
        minHeight: "100%",
    },
    pageContent: {
        marginTop: 30,
        marginBottom: 30,
    },
    pageTitle: {
        paddingTop: 35,
        paddingBottom: 35,
    },
    sidebar: {},
});

export type LayoutProps = {
    children: React.ReactNode;
    className?: string;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    sidebar?: React.ReactNode;
    maxWidth?: ContainerProps["maxWidth"];
    fullWidth?: boolean;
    style?: ContainerProps["style"];
};
export const Layout = ({
    children,
    header,
    footer,
    sidebar,
    maxWidth = "sm",
    className,
    fullWidth,
    style,
}: LayoutProps) => {
    const { classes, cx } = useStyles();

    return (
        <Container
            maxWidth={fullWidth ? false : maxWidth}
            disableGutters
            className={cx(classes.page, className)}
            style={style}
        >
            <Box display="flex" flexDirection="row" minHeight={"100vh"}>
                {sidebar && <aside className={classes.sidebar}>{sidebar}</aside>}
                <Box flex={1} maxWidth={"100%"}>
                    <Box display="flex" flexDirection="column" minHeight={"100vh"} maxWidth={"100%"}>
                        <Box flex={1}>
                            {header}
                            {children}
                        </Box>
                        {footer && <footer>{footer}</footer>}
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export const LayoutTitle = ({ className, ...props }: TextBaseProps) => {
    const { classes, cx } = useStyles();
    return <TextTitle className={cx(classes.pageTitle, className)} {...props} />;
};

export const LayoutTitle2 = ({ className, ...props }: TextBaseProps) => {
    const { classes, cx } = useStyles();
    return <TextTitle2 className={cx(classes.pageTitle, className)} {...props} />;
};

export const LayoutTitle3 = ({ className, ...props }: TextBaseProps) => {
    const { classes, cx } = useStyles();
    return <TextTitle3 className={cx(classes.pageTitle, className)} {...props} />;
};

export const LayoutContainer = ({ maxWidth = "lg", className, ...props }: ContainerProps) => {
    return <Container maxWidth={maxWidth} className={className} {...props} />;
};

export const LayoutContent = ({ className, ...props }: ContainerProps) => {
    const { classes, cx } = useStyles();

    return <LayoutContainer className={cx(classes.pageContent, className)} {...props} />;
};

export default Layout;
