import { useTranslation as useNextTranslation, UseTranslationOptions } from "react-i18next";

export { useNextTranslation };

/**
 * @deprecated use useNextTranslation() instead
 */
export const useTranslation = (key: string, options: UseTranslationOptions | undefined = undefined) => {
    const { t } = useNextTranslation();
    return options ? t(key, options) : t(key);
};

export default useTranslation;
