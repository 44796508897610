import { BoxProps, Box } from "@mui/material";

export interface FlexProps extends Omit<BoxProps, "top" | "bottom"> {
    row?: boolean;
    rowReverse?: boolean;
    column?: boolean;
    start?: boolean;
    center?: boolean;
    end?: boolean;
    top?: boolean;
    middle?: boolean;
    bottom?: boolean;
    spaceBetween?: boolean;
    spaceAround?: boolean;
    wrap?: boolean;
    gap?: string | number;
    grow?: boolean | number;
}
export const Flex = ({
    row = true,
    rowReverse,
    column,
    start,
    center,
    end,
    top,
    middle,
    bottom,
    spaceBetween,
    spaceAround,
    wrap,
    gap,
    grow,
    ...props
}: FlexProps) => {
    let flexDirection: FlexProps["flexDirection"];
    let justifyContent: FlexProps["justifyContent"];
    let alignItems: FlexProps["alignItems"];

    if (row) flexDirection = "row";
    if (rowReverse) flexDirection = "row-reverse";
    if (column) flexDirection = "column";

    if (start) justifyContent = "flex-start";
    else if (center) justifyContent = "center";
    else if (end) justifyContent = "flex-end";
    else if (spaceBetween) justifyContent = "space-between";
    else if (spaceAround) justifyContent = "space-around";

    if (top) alignItems = "flex-start";
    else if (middle) alignItems = "center";
    else if (bottom) alignItems = "flex-end";

    let flexGrow;
    if (grow) {
        flexGrow = typeof grow === "number" ? grow : 1;
    }

    const flexWrap = wrap ? "wrap" : "nowrap";

    const style = props.style ?? { gap: "0px" };
    if (gap) style.gap = gap;

    return (
        <Box
            flexDirection={flexDirection}
            justifyContent={justifyContent}
            alignItems={alignItems}
            flexWrap={flexWrap}
            style={style}
            flexGrow={flexGrow}
            display="flex"
            {...props}
        />
    );
};
